.App-header{
  border-bottom: 1px solid black;
}

.App-header h1{
  margin: 0.5em;
  font-size: 1.5em;
}

.App-main{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 100%;
}

.bloc-lien{
  width: 300px;
  height: 300px;
  margin: 2px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bloc-lien a{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
